import './index.scss';
import 'bootstrap';
import turretFavicon from './public/img/message-favicon.png';
import 'jquery';

const titleLines = [
    "Hello?! Please don't leave me",
    "I did everything you asked",
    "I don't blame you",
    "Noooo",
    "See you soon",
    "Come closer",
    "Something's wrong",
    "What are you doing",
    "Failure",
    "This is not good",
    "Um, hello",
    "Oh my",
    "I blame myself",
    "Hello",
    "Whyyyy",
    "I did everything you askedddd",
    "I don't understandddd",
    "I'm fineeee",
    "Ahhhh",
    "Wheee oh noooo",
    "Whyyy",
    "Hellooo",
    "Searching",
    "Canvassing",
    "Is anyone there",
    "Could you come over here",
    "Excuse me",
    "I don't blame you",
    "I don't hate you",
    "Whyyyy",
    "No hard feelings",
    "Uh oh",
    "Are you still there",
    "Target lost",
    "Can I help you",
    "Searching",
    "Hey, it's me",
    "But I need to protect the humans"
];

const favicon = document.getElementById('favicon') as HTMLLinkElement;
const ogFavicon = favicon?.href ?? '';
const ogTitle = document.title;

window.addEventListener('focus', function () {
    document.title = ogTitle;
    favicon.href = ogFavicon;
});

window.addEventListener('blur', function () {
    document.title = titleLines[Math.floor(Math.random() * titleLines.length)];
    favicon.href = turretFavicon;
});

const simpleDebounce = (callback) => {
    let frame;
    return (...params) => {
        if (frame) {
            cancelAnimationFrame(frame);
        }

        frame = requestAnimationFrame(() => {
            callback(...params);
        });
    }
};

const storeScroll = () => {
    document.documentElement.dataset.scroll = `${window.scrollY}`;
}
document.addEventListener('scroll', storeScroll, { passive: true });
storeScroll();

// youtube audio player
const tag = document.createElement('script');
tag.src = 'https://www.youtube.com/iframe_api';
const firstScriptTag = document.getElementsByTagName('script')[0] as HTMLScriptElement;
firstScriptTag.parentNode!.insertBefore(tag, firstScriptTag);

let player;
let playerRef;

// This function gets called when the YouTube API script has loaded
declare global {
    interface Window {
        onYouTubeIframeAPIReady: () => void;
    }
}

function onPlayerReady(event) {
    playerRef = event.target;
}

function onPlayerStateChange(event) {
    // Do something when the player's state changes
    // if (event.data === (window as any).YT.PlayerState.ENDED) {
    //     playerRef = null;
    // }
}


window.onYouTubeIframeAPIReady = () => {
    // Create an instance of the YouTube player
    player = new (window as any).YT.Player('player', {
        height: '0', // Set to 0 for audio only
        width: '0',  // Set to 0 for audio only
        videoId: '8vFXMk8DoRs',  // Replace with the actual video ID
        playerVars: {
            controls: 0,  // Hide YouTube controls
            disablekb: 1,  // Disable keyboard controls
            enablejsapi: 1,  // Enable the JavaScript API
            iv_load_policy: 3,  // Turn off annotations
            modestbranding: 1,  // Remove YouTube logo
            playsinline: 1,  // Play inline on mobile
            start: 0  // Start from the beginning
        },
        events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange
        }
    });
}


function set_volume(volume) {
    playerRef?.setVolume(volume);
}

function set_playing(isPlaying = false) {
    if (isPlaying) {
        playerRef?.playVideo();
    } else {
        playerRef?.pauseVideo();
    }
}


const psyl = document.querySelector("psyl") as HTMLElement;
const psylac = document.querySelector("psylac") as HTMLElement;
const mainEl = document.querySelector("main") as HTMLElement;
if (psylac && mainEl && psyl) {
    const b = 1337;
    const c = 420;
    const psylcn = 'psych-me-daddy';
    const ddMegaDooDoo = .1337
    const baseVolume = 10;
    let psylactivating = 0;
    let permissionGranted = false;
    let clickCount = 0;

    if (navigator.mediaDevices) {
        function eventHandler(event) {
            clickCount++;
            if (clickCount == 1) {
                psylac.style.cursor = 'wait';
                (navigator.mediaDevices as any).selectAudioOutput()
                    .then((device) => {
                        console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
                    })
                    .catch((err) => {
                        console.warn(`${err.name}: ${err.message}`);
                    });
            }
            // request audio permission
            if (clickCount == 2) {
                psylac.style.cursor = 'pointer';
            }
            if (clickCount == 3) {
                psylac.style.cursor = 'crosshair';
            }
            if (clickCount == 4) {
                psylac.style.cursor = 'default';
            }
            if (clickCount > 4) {
                psylac.style.cursor = 'wait';
                event.preventDefault();
                playerRef?.playVideo();
                playerRef?.setVolume(baseVolume);
                psylactivating = 1;
            }
        }
        psylac.addEventListener("mouseenter", eventHandler);
        psylac.addEventListener("click", eventHandler);

        const loop = () => {
            if (psylactivating > 0 && psylactivating < b) psylactivating += 1;

            if (psylactivating > c && psylactivating < b) {
                psyl.style.opacity = (psylactivating - c) / (b - c) * ddMegaDooDoo + '';
                set_volume(baseVolume + (psylactivating - c) / (b - c) * 40);
            }

            if (psylactivating > c * 2 && psylactivating < b) {
                if (!mainEl.classList.contains(psylcn)) {
                    mainEl.classList.add(psylcn);
                }
                return;
            }
            requestAnimationFrame(loop);
        }
        loop(); // kick off the loop
    }
